import React, {useEffect, useState, useRef } from 'react';
import { TimelineMax,TimelineLite } from "gsap";


export default function(){
    // const content = useRef(null)
    // const head = useRef(null)
    // const subhead = useRef(null)
    // const [tl] = useState(new TimelineLite({paused: true}));
    // const logoTl = new TimelineLite({ paused:true });
    // const [tl] = useState(gsap.timeline({paused: true));

    // let tl = TimelineLite({repeat: 2, repeatDelay: 1});
    useEffect(() => {
        const tl = new TimelineMax()

        tl.to("#content", 2, {opacity:1})
          .fromTo("#head", 2, {marginLeft:-800, opacity:0}, {marginLeft:0, opacity:1}, 2)
          .fromTo("#subhead", 2, {width:0}, {width:355}, 2)
      
			// tlm.fromTo('#subhead', 4, { left: -100, autoAlpha: 0 }, "-=0.25") 
  
        // new TimelineLite ()
        // .to(title1, {x: 100, duration: 1}, ">1");
        // TweenMax.fromTo(
        //   [title.current,title1.current],
        //   0.5,
        //   { y: 18 },
        //   { y: -18, yoyo: true, repeat: -1 }
        // );
        // TweenMax.fromTo(
        //   [title1.current,title2.current],
        //   0.5,
        //   { y: 18 },
        //   { y: -18, yoyo: true, repeat: -1 }
        // );


      }, []);
    return(
	<div id="content">
    <h1  id= "head">De Thinking Co</h1> 
    <h3  id= "subhead">Ready to grow your website to a new level</h3>
    </div>

     
        
    )
}




